<template>
  <div class="row">
    <div class="col-12">
      <q-form ref="editForm">
        <!-- 대상공정 상세 -->
        <c-card title="LBL0001065" class="cardClassDetailForm tpd">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- 대상공정 저장 LBL0001067 -->
              <c-btn 
                v-if="editable && isSelect" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="process"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveProcess"
                @btnCallback="saveProcessCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <template v-if="checkProcessCd">
                <!-- 단위공정 -->
                <c-process
                  :editable="editable"
                  :disabled="true"
                  label="LBL0001068"
                  name="processCd"
                  v-model="process.processCd">
                </c-process>
              </template>
              <template v-else>
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="LBLPROCESS"
                  name="processName"
                  v-model="process.processName">
                </c-text>
              </template>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <template v-if="!vendorFlag">
                <!-- 평가부서 -->
                <c-dept
                  :editable="editable"
                  :disabled="!isSelect"
                  :required="true"
                  type="edit"
                  label="LBL0001069"
                  name="assessDeptCd"
                  v-model="process.assessDeptCd">
                </c-dept>
              </template>
              <template v-else>
                <!-- 평가업체 -->
                <c-vendor
                  :editable="editable"
                  :disabled="!isSelect"
                  type="edit"
                  label="LBL0001070"
                  name="assessDeptCd"
                  v-model="process.assessDeptCd">
                </c-vendor>
              </template>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <!-- 평가담당자 -->
              <c-field 
                :isWrite="assessPlan.ramTechniqueCd==='RT00000025'"
                :required="true" 
                :editable="editable"
                :disabled="!isSelect" 
                :data="process"
                type="user" 
                userName="assessUserName"
                label="LBL0001071" 
                name="assessUserId" 
                v-model="process.assessUserId" />
            </div>
            <div v-if="assessPlan.ramTechniqueCd==='RT00000025'" class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <!-- 작업발생주기 -->
              <c-select
                :editable="editable"
                :disabled="!isSelect" 
                codeGroupCd="WORK_CYCLE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="workCycleCd"
                label="LBL0001072"
                v-model="process.workCycleCd"
              ></c-select>
            </div>
            <div v-if="assessPlan.ramTechniqueCd==='RT00000025'" class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <!-- 작업시간(1회) -->
              <c-text
                :editable="editable"
                :disabled="!isSelect" 
                type="number"
                label="LBL0001073"
                name="workingTime"
                v-model="process.workingTime">
              </c-text>
            </div>
            <div v-if="assessPlan.ramTechniqueCd==='RT00000030'" class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <!-- 작업환경측정 -->
              <c-text
                :editable="editable"
                :disabled="true"
                :afterIcon="editable && isSelect ? [
                  { name: 'search', click: true, callbackName: 'searchWorkMeasure', color: 'teal' },
                  { name: 'close', click: true, callbackName: 'removeWorkMeasure', color: 'red' }
                ] : null"
                label="LBL0001074"
                name="measPlanContent"
                v-model="process.measPlanContent"
                @searchWorkMeasure="searchWorkMeasure"
                @removeWorkMeasure="removeWorkMeasure">
              </c-text>
            </div>
            <div v-if="assessPlan.ramTechniqueCd==='RT00000030'" class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
              <!-- 사용온도(℃) -->
              <c-text
                :required="true" 
                :disabled="!isSelect"
                :editable="editable"
                type="number"
                label="LBL0001075"
                name="temperature"
                v-model="process.temperature">
              </c-text>
            </div>
            <div v-if="assessPlan.ramTechniqueCd==='RT00000001'" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <!-- 관련설비 -->
              <c-tag 
                :editable="editable"
                :disabled="!isSelect"
                :clickable="true"
                itemText="equipmentName"
                itemValue="check"
                label="LBL0001076"
                name="equipments" 
                v-model="process.equipments"
                @removeTag="removeEquipment"
                @addTag="addEquipment"
                @clickTag="clickTag"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-6">
              <!-- 공정특징설명 -->
              <c-textarea
                :editable="editable"
                :disabled="!isSelect"
                label="LBL0001077"
                name="processExplainFeature"
                v-model="process.processExplainFeature">
              </c-textarea>
            </div>
          </template>
        </c-card>
        <component
          :is="subComponent"
          :process.sync="process"
          :assessPlan.sync="assessPlan"
          :disabled.sync="disabled"
          :updateBtnData.sync="updateBtnData"
          :vendorFlag.sync="vendorFlag"
          @saveProcess="emitSaveProcess"
        />
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskTargetProcessDetail',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        processes: [], // 대상공정 목록
      }),
    },
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workingTime: 0,  // 작업시간(1회)
        vendorFlag: 0,
        temperature: null,
        workMeasurementPlanId: '',
        measPlanContent: '',
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        sops: [],
        drawNodes: [],
        draws: [],
        fmScenarios: [],
        hazopScenarios: [],
        scenarios: [],
        charmResultScenarios: [],
        charmEstimationScenarios: [],
        targets: [],
        thirdScenarios: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    vendorFlag: {
      type: Boolean,
      default: () => ({
        flag: false,
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      // subComponent: () => import(`${'./4mTargetJobRisk.vue'}`),
      editable: true,
      saveUrl: '',
      isSave: false,
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd 
        && !this.disabled 
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    checkProcessCd() {
      return this.process.processCd.indexOf('WTE') === -1
    },
    subComponent() {
      let components = null;
      switch(this.assessPlan.ramTechniqueCd) {
        case 'RT00000001':  // HAZOP
          components = () => import(`${'@/pages/ram/hazop/plan/hazopTargetPidNode.vue'}`)
          break
        case 'RT00000005': // K-PSR
          components = () => import(`${'@/pages/ram/kpsr/plan/kpsrTargetPidNode.vue'}`)
          break
        case 'RT00000010': // Check-list
          components = () => import(`${'@/pages/ram/checklist/plan/checklistTargetItem.vue'}`)
          break
        case 'RT00000015' : // JRA
          components = () => import(`${'@/pages/ram/jsa/plan/jsaTargetJobRisk.vue'}`)
          break
        case 'RT00000020' : // KRAS
          components = () => import(`${'@/pages/ram/kras/plan/krasTargetJobRisk.vue'}`)
          break
        case 'RT00000025' : // 4M
          components = () => import(`${'@/pages/ram/4m/plan/4mTargetJobRisk.vue'}`)
          break
        case 'RT00000030' : // CHARM
          components = () => import(`${'@/pages/ram/charm/plan/charmTargetChem.vue'}`)
          break
        case 'RT00000035' : // 3단계 판단법
          components = () => import(`${'@/pages/ram/third/plan/thirdTargetRiskHazard.vue'}`)
          break
      }
      return components;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.ram.assessProcess.update.url
      // code setting
      // list setting
    },
    emitSaveProcess(processCd) {
      this.$emit('saveProcess', processCd)
    },
    searchWorkMeasure() {
      this.popupOptions.title = 'LBL0001078'; // 작업환경측정 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd,
        processCd: this.process.processCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/wmr/workMeasureResultPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkMeasurePopup;
    },
    closeWorkMeasurePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$set(this.process, 'workMeasurementPlanId', data[0].workMeasurementPlanId)
        this.$set(this.process, 'measPlanContent', data[0].measPlanContent)
      }
    },
    removeWorkMeasure() {
      this.$set(this.process, 'workMeasurementPlanId', null)
      this.$set(this.process, 'measPlanContent', null)
    },
    removeEquipment(item) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGEXCEPT', // 제외하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.process.equipments = this.$_.reject(this.process.equipments, item)
        },
        // 취소 callback 함수
        cancelCallback: () => {
          item.check = true
        },
      });
    },
    addEquipment() {
      this.popupOptions.title = 'LBL0001079'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.process.equipments) {
          this.process.equipments = [];
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.equipments, { equipmentCd: item.equipmentCd }) === -1) {
            this.process.equipments.push({
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
            })
          }
        })
      }
    },
    clickTag(tag) {
      this.popupOptions.title = 'LBL0001080'; // 설비 상세
      this.popupOptions.param = tag;
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveProcess() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.process.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveProcessCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
    },
  }
};
</script>
<style lang="sass">
.tpd 
  .q-pa-sm
    padding: 15px !important
    margin-bottom: -40px !important
</style>